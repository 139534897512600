<template>
  <SettingsPanel type="basic" :scale-labels="false">
    <Checkbox
      slot="switch"
      v-model="showHeatmap"
      type="switch"
      :loading="isHeatmapLoading"
      :label="$t('gps.coverageMap')">
    </Checkbox>
  </SettingsPanel>
</template>

<script>
export default {
  computed: {
    isHeatmapLoading () {
      return this.$store.getters['gps/heatmapLoading'] && this.showHeatmap
    },

    showHeatmap: {
      get () {
        return this.$store.getters['gps/heatmapVisible']
      },

      set (state) {
        this.$store.commit('gps/setHeatmapVisibility', state)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.settings-panel {
  z-index: 30;
}
</style>
