<template>
  <Fragment>
    <div v-if="isVisible" class="gps-controls box">
      <div v-if="!isLoading" class="drag-elapsed">
        <RangeSlider
          v-model="elapsed"
          :max="positions.length - 1"
          :step="1"
          value="0"
          min="0">
          <Fragment slot="timeline">
            <div
              v-for="(segment, i) in segments"
              :key="i"
              :style="[getSliderClass(segment)]"
              class="drag-elapsed-segment">
            </div>
          </Fragment>
        </RangeSlider>

        <div class="drag-elapsed-timer">{{ currentTimeStamp }} / {{ durationAsDateTime }}</div>
      </div>

      <ContentPlaceholders v-else rounded>
        <ContentPlaceholdersText :lines="1" />
      </ContentPlaceholders>
    </div>
  </Fragment>
</template>

<script>
import RangeSlider from '@/components/ui/rangeSlider/'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

const coverageToSliderSegmentColor = {
  0: '#222222', // Black
  1: '#e74c3c', // Red
  3: '#f1c40f', // Yellow
  4: '#ecf0f1' // Grey
}

export default {
  components: {
    RangeSlider
  },

  props: {
    segments: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapGetters({
      selectedDate: 'gps/selectedDate',
      positions: 'gps/positions',
      duration: 'gps/playback/duration',
      currentLocation: 'gps/playback/currentLocation'
    }),

    isVisible () {
      return this.selectedDate !== null
    },

    isLoading () {
      return this.positions === null
    },

    elapsed: {
      get () {
        return this.$store.getters['gps/playback/elapsed']
      },

      set (elapsed) {
        this.$store.commit('gps/playback/elapsed', Number(elapsed))
        this.$store.commit('gps/playback/setCurrentLocation', this.positions[elapsed])
      }
    },

    currentTimeStamp () {
      return dayjs(this.currentLocation.timestamp).format('HH:mm:ss')
    },

    durationAsDateTime () {
      return dayjs(this.positions[0].timestamp.getTime() + (this.duration * 1000)).format('HH:mm:ss')
    }
  },

  methods: {
    getSliderClass (segment) {
      const segmentCoverage = segment.coverage
      let color = coverageToSliderSegmentColor[segmentCoverage]

      return {
        width: (segment.positions.length * 100) / this.positions.length + '%',
        background: color
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.gps-controls {
  padding: 0.75rem 1.25rem;
  position: absolute;
  right: 1.5rem;
  bottom: 1.5rem;
  left: 1.5rem;
  margin-bottom: 0 !important;
}

.drag-elapsed {
  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-timer {
    margin-left: 0.75rem;
    white-space: nowrap;
    display: none;

    @media screen and (min-width: 1024px) {
      display: block;
    }
  }
}
</style>
