<template>
  <div class="page">
    <Titlebar class="position-is-absolute">
      <h5 slot="title" class="title is-5 is-marginless">{{ $t('gps.coverage') }}</h5>

      <div slot="local">
        <SettingsButton />
      </div>
    </Titlebar>

    <GoogleMaps id="gps-map">
      <ZoomButtons />

      <GpsCoverageDatePickerPanel />
      <GpsCoverageInfoPanel />
      <GpsCoverageSlider :segments="segments" />

      <GpsCoverageHeatmap />

      <GpsCoverageTrail v-if="positions !== null" :segments="segments" />
      <GpsCoverageMarker v-if="positions !== null" :segments="segments" />

      <GpsCoverageSettingsPanel />
    </GoogleMaps>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import GpsCoverageDatePickerPanel from './GpsCoverageDatePickerPanel.vue'
import GpsCoverageInfoPanel from './GpsCoverageInfoPanel.vue'
import GpsCoverageSlider from './GpsCoverageSlider.vue'
import GpsCoverageMarker from './GpsCoverageMarker.vue'
import GpsCoverageHeatmap from './GpsCoverageHeatmap.vue'
import GpsCoverageTrail from './GpsCoverageTrail.vue'
import GpsCoverageSettingsPanel from './GpsCoverageSettingsPanel.vue'

export default {
  components: {
    GpsCoverageDatePickerPanel,
    GpsCoverageInfoPanel,
    GpsCoverageSlider,
    GpsCoverageMarker,
    GpsCoverageTrail,
    GpsCoverageHeatmap,
    GpsCoverageSettingsPanel
  },

  data () {
    return {
      segments: []
    }
  },

  computed: {
    ...mapGetters({
      positions: 'gps/positions',
      historyDates: 'gps/historyDates',
      gps: 'gps/selected',
      currentLocation: 'gps/playback/currentLocation'
    })
  },

  watch: {
    gps () {
      this.readHistoryDates()
    },

    positions (positions) {
      if (positions) {
        this.initializePlayback(positions)
        this.segments = this.createPositionSegments(positions, 0)
      } else {
        this.segments = []
      }
    }
  },

  mounted () {
    this.readHistoryDates()
  },

  methods: {
    async readHistoryDates () {
      this.$store.dispatch('gps/readHistoryDates')
    },

    initializePlayback (positions) {
      const startTimeInSeconds = positions[0].timestamp.getTime() / 1000
      const endTimeInSeconds = positions[positions.length - 1].timestamp / 1000

      const duration = Math.round(endTimeInSeconds - startTimeInSeconds)

      this.$store.commit('gps/playback/duration', duration)
      this.$store.commit('gps/playback/elapsed', 0)
    },

    createPositionSegments (allPositions, segmentStartPositionIndex) {
      const segmentFirstPosition = allPositions[segmentStartPositionIndex]
      const segmentCoverage = this.calculateCoverage(segmentFirstPosition)

      const segment = {
        startPositionIndex: segmentStartPositionIndex,
        coverage: segmentCoverage,
        positions: []
      }

      // If the position just before the start of this segment has better coverage than this
      // segment add a line to the end of the previous segment. This means that lines between
      // positions with different coverage always displayes the lower coverage.
      if (segmentStartPositionIndex > 0) {
        const previousPosition = allPositions[segmentStartPositionIndex - 1]
        const previousPositionCoverage = this.calculateCoverage(previousPosition)
        if (previousPositionCoverage > segmentCoverage) {
          segment.positions.push(allPositions[segmentStartPositionIndex - 1])
          segment.startPositionIndex = segmentStartPositionIndex - 1
        }
      }

      for (let i = segmentStartPositionIndex; i < allPositions.length; i++) {
        const position = allPositions[i]
        const positionCoverage = this.calculateCoverage(position)

        if (positionCoverage === segmentCoverage) {
          // Continue to add positions to this segment as long as the coverage does not change.
          segment.positions.push(position)
        } else {
          // Add first position of the next segment to the end of this segment following the same logic
          // as for adding a line to the previous segment above.
          if (positionCoverage > segmentCoverage) {
            segment.positions.push(position)
            segment.endPositionIndex = i
          } else {
            segment.endPositionIndex = i - 1
          }

          return [segment, ...this.createPositionSegments(allPositions, i)]
        }
      }

      return [segment]
    },

    calculateCoverage (position) {
      if (!position) {
        return null
      }

      let csq = position.csq
      let gps = this.calculateGPS(position)
      let latency = this.calculateLatency(position)

      if (!csq && !gps && !latency) {
        return null
      }

      if ((gps !== null && gps === 0) ||
        (csq !== null && csq === 0) ||
        (latency !== null && latency > 120)) {
        return 0
      }

      if ((gps !== null && gps <= 60) ||
        (csq !== null && csq < 4) ||
        (latency !== null && latency >= 15)) {
        return 1
      }

      if ((gps !== null && gps <= 80) ||
        (csq !== null && csq < 8) ||
        (latency !== null && latency >= 5)) {
        return 3
      }

      return 4
    },

    calculateGPS (position) {
      if (!position) {
        return null
      }

      const signals = {
        1: 100,
        11: 80,
        21: 60,
        31: 40,
        41: 20,
        51: 0
      }

      const signal = position.gpsSignal
      return signals[signal]
    },

    calculateLatency (position) {
      if (!position) {
        return null
      }

      const a = new Date(position.timestamp)
      const b = new Date(position.received)

      const difference = (b - a) / 1000

      return difference
    }
  }
}
</script>
