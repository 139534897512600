<script>
const coverageToMapSegmentColor = {
  0: '#00000000', // Transparent, segments of "dead" positions should not be visible
  1: '#e74c3c', // Red
  3: '#f1c40f', // Yellow
  4: '#2ecc71' // Green
}

export default {
  props: {
    segments: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      polylines: []
    }
  },

  async mounted () {
    const map = await this.$parent.getMap()
    const positions = this.$store.getters['gps/positions']

    this.segments.forEach(segment => {
      this.polylines.push(this.createPolyline(segment, map))
    })

    this.fitBounds(positions, map)
  },

  destroyed () {
    this.polylines.forEach(polyline => {
      polyline.setMap(null)
    })
  },

  render () {
    return ''
  },

  methods: {
    createPolyline (segment, map) {
      const coordinates = segment.positions.map(position => {
        return new window.google.maps.LatLng(position.location)
      })

      const segmentCoverage = segment.coverage
      let color = coverageToMapSegmentColor[segmentCoverage]

      const polyline = new window.google.maps.Polyline({
        map: map,
        clickable: false,
        path: coordinates,
        geodesic: true,
        strokeColor: color,
        strokeOpacity: 1.0,
        strokeWeight: 4
      })

      return polyline
    },

    fitBounds (positions, map) {
      if (!positions) {
        return
      }

      const bounds = new window.google.maps.LatLngBounds()

      positions.forEach(coordinate => {
        bounds.extend(new window.google.maps.LatLng(coordinate.location))
      })

      map.fitBounds(bounds)
    }
  }
}
</script>
