<template>
  <MapMarker
    :icon="icon"
    :location="markerLocation"
    :anchor="anchor">
  </MapMarker>
</template>

<script>
import { mapGetters } from 'vuex'
import { getMarkerTypeIconUrl } from '@/api/map/markers/markers'
import MapMarker from '@/modules/map/components/MapMarker.vue'

export default {
  components: {
    MapMarker
  },

  props: {
    segments: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      markerLocation: {
        lat: 0,
        lng: 0
      }
    }
  },

  computed: {
    ...mapGetters({
      elapsed: 'gps/playback/elapsed',
      markerSize: 'map/markers/size'
    }),

    icon () {
      return getMarkerTypeIconUrl('dog')
    },

    iconSize () {
      return this.markerSize * 32
    },

    anchor () {
      return {
        x: this.iconSize / 2,
        y: this.iconSize / 2
      }
    }
  },

  watch: {
    elapsed (newElapsed, oldElapsed) {
      const markerPosition = this.calculateMarkerPosition(newElapsed, oldElapsed)
      if (!markerPosition) {
        return
      }

      this.markerLocation = markerPosition.location
    }
  },

  async mounted () {
    var initialPosition = null
    if (this.segments && this.segments.length > 0) {
      initialPosition = this.segments[0].positions[0].location
    }

    this.markerLocation = initialPosition
  },

  methods: {
    calculateMarkerPosition (newElapsed, oldElapsed) {
      // Marker should never move to a position where the coverage is 0
      // This method finds the segment where the current position based on elapse exists and
      // returns the new marker position

      const matchingSegmentIndex = this.findSegmentIndexForElapsed(newElapsed)

      if (!matchingSegmentIndex) {
        return null
      }

      const matchingSegment = this.segments[matchingSegmentIndex]

      if (matchingSegment.coverage > 0) {
        // Coverage is ok so return position
        const segmentPositionIndex = newElapsed - matchingSegment.startPositionIndex

        return matchingSegment.positions[segmentPositionIndex]
      }

      // Segment coverage is 0
      // Find the closest segment with coverage > 0 and return the first/last position in that
      // segmant depending on which direction the user is dragging the slider

      const segementSearchDirection = newElapsed > oldElapsed ? -1 : 1 // -1 if the user is dragging to the right
      for (let segmentIndex = matchingSegmentIndex + segementSearchDirection; segmentIndex >= 0 && segmentIndex < this.segments.length; segmentIndex += segementSearchDirection) {
        const segment = this.segments[segmentIndex]

        if (segment.coverage > 0) {
          if (segementSearchDirection > 0) {
            // Dragging to the left
            // Return first position in the next segment
            return segment.positions[0]
          } else {
            // Dragging to the right
            // Return last position in the previous segment
            return segment.positions[segment.positions.length - 1]
          }
        }
      }

      return null
    },

    findSegmentIndexForElapsed (elapsed) {
      if (!this.segments) {
        return null
      }

      for (let segmentIndex = 0; segmentIndex < this.segments.length; segmentIndex++) {
        const segment = this.segments[segmentIndex]

        if (elapsed >= segment.startPositionIndex && elapsed <= segment.endPositionIndex) {
          return segmentIndex
        }
      }

      return null
    }
  }
}
</script>
