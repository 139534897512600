<template>
  <HeatmapLayer
    v-if="heatmapData !== null"
    :data="heatmapData"
    :gradient="heatmapGradient"
    :visible="heatmapVisible"
    :radius="0.01"
    :maxIntensity="50"
    :dissipating="false" />
</template>

<script>
import { mapGetters } from 'vuex'
import GpsService from '../../services/gpsService'

export default {
  data () {
    return {
      heatmapGradient: [
        'rgba(235, 75, 75, 0)',
        'rgba(200, 40, 40, 1)'
      ],

      heatmapData: null
    }
  },

  computed: {
    ...mapGetters({
      center: 'map/getCenter',
      positions: 'gps/positions',
      heatmapVisible: 'gps/heatmapVisible'
    })
  },

  watch: {
    center (latLng) {
      if (latLng !== null) {
        this.readHeatmapData(latLng)
      }
    },
    positions (positions) {
      if (positions) {
        this.readHeatmapData(this.getHeatmapCenterPoint(positions))
      } else {
        this.heatmapData = null
      }
    }
  },

  methods: {
    // HeatmapLayer requires a <GoogleMaps> component to be direct parent
    // so we just mimic this behaviour.
    getMap () {
      return this.$parent.getMap()
    },

    async readHeatmapData (center) {
      try {
        this.heatmapData = null
        this.$store.commit('gps/setHeatmapLoading', true)
        this.heatmapData = await GpsService.readGsmCoverage(center.toJSON())
        this.$store.commit('gps/setHeatmapLoading', false)
      } catch (error) {
        this.$notification.danger(this.$t('gps.loadGpsCoverageFailedErrorMessage'))
        console.error(error)
      }
    },

    getHeatmapCenterPoint (positions) {
      const bounds = new window.google.maps.LatLngBounds()

      positions.forEach(coordinate => {
        return bounds.extend(new window.google.maps.LatLng(coordinate.location))
      })

      return bounds.getCenter()
    }
  }
}
</script>
