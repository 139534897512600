<template>
  <Panel :isActive="true">
    <PanelContent scrollable>
      <h6 class="title is-6 push-bottom-xs">{{ $t('gps.pickDate') }}</h6>

      <InlineLoader v-if="isLoading" />

      <div v-else>
        <FlatPickr
          v-if="historyDates.length > 0"
          v-model="date"
          :config="dateConfig"
          class="input" />

        <p v-else class="push-top-xxs is-marginless-bottom">
          <Icon name="icon-alert-circle" /> {{ $t('gps.noPositions') }}
        </p>
      </div>
    </PanelContent>
  </Panel>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
  computed: {
    ...mapGetters({
      historyDates: 'gps/historyDates'
    }),

    isLoading () {
      return this.historyDates === null
    },

    dateConfig () {
      return {
        enableTime: false,
        dateFormat: 'Y-m-d',
        enable: this.historyDates,
        minDate: dayjs().subtract(3, 'month').subtract(1, 'day').toDate(),
        maxDate: new Date(),
        locale: {
          firstDayOfWeek: 1
        }
      }
    },

    date: {
      get () {
        return this.$store.getters['gps/selectedDate']
      },

      set (date) {
        return this.$store.commit('gps/setSelectedDate', date)
      }
    }
  },

  watch: {
    date (date) {
      if (date !== null) {
        this.readGpsPositions(date)
      }
    }
  },

  methods: {
    async readGpsPositions (date) {
      try {
        await this.$store.dispatch('gps/readGpsPositions')
      } catch (error) {
        this.$notification.danger(this.$i18n.t('gps.loadGpsDataFailedErrorMessage'))
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  margin-top: 3.25rem !important;

  .panel-content {
    padding: 0.75rem;
  }
}
</style>
