<template>
  <Panel :isActive="isVisible">
    <PanelContent scrollable>
      <div v-if="!isLoading" class="media">
        <div class="media-content">
          <h6 class="title is-6 push-bottom-xs">{{ currentTime }}</h6>

          <ul>
            <li>
              {{ $t('gps.coverage') }} <IconBars :bars="coverage" />
            </li>

            <li>
              {{ $t('gps.gps') }}: {{ gps }}%
            </li>

            <li>
              {{ $t('gps.gsm') }}: {{ csq }}
            </li>

            <li>
              {{ $t('gps.latency') }}: {{ latency }}s
            </li>
          </ul>
        </div>

        <div class="media-right">
          <div
            :class="{ 'is-active': isActive }"
            class="dropdown is-up">
            <div class="dropdown-trigger">
              <button
                class="button is-small"
                aria-haspopup="true"
                @click="isActive = true">
                {{ $t('gps.info') }}
              </button>
            </div>

            <div class="dropdown-menu" role="menu">
              <div class="dropdown-content">
                <div class="dropdown-item">
                  <button class="delete" @click="isActive = false"></button>

                  <h6 class="title is-6 push-bottom-xs">{{ $t('gps.gps') }}</h6>
                  <p v-if="gps === 100" v-html="$t('gps.gpsContentGood')"></p>
                  <p v-if="gps === 80" v-html="$t('gps.gpsContentDecent')"></p>
                  <p v-if="gps < 80" v-html="$t('gps.gpsContentBad1')"></p>
                  <p v-if="gps < 80" v-html="$t('gps.gpsContentBad2')"></p>

                  <h6 class="title is-6 push-bottom-xs">{{ $t('gps.gsm') }}</h6>
                  <p v-if="csq >= 8" v-html="$t('gps.gsmContentGood')"></p>
                  <p v-if="csq > 4 && csq <= 8" v-html="$t('gps.gsmContentDecent')"></p>
                  <p v-if="csq <= 4" v-html="$t('gps.gsmContentBad')"></p>

                  <h6 class="title is-6 push-bottom-xs">{{ $t('gps.latency') }}</h6>
                  <p v-if="latency < 5" class="is-marginless" v-html="$t('gps.latencyContentGood')"></p>
                  <p v-if="latency >= 5 && latency <= 20" class="is-marginless" v-html="$t('gps.latencyContentDecent')"></p>
                  <p v-if="latency > 20" class="is-marginless" v-html="$t('gps.latencyContentBad')"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <ContentPlaceholders rounded>
          <ContentPlaceholdersHeading />
        </ContentPlaceholders>

        <p></p>

        <ContentPlaceholders rounded>
          <ContentPlaceholdersText :lines="4" />
        </ContentPlaceholders>
      </div>
    </PanelContent>
  </Panel>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
  data () {
    return {
      isActive: false
    }
  },

  computed: {
    ...mapGetters({
      selectedDate: 'gps/selectedDate',
      positions: 'gps/positions'
    }),

    isVisible () {
      return this.selectedDate !== null
    },

    isLoading () {
      return this.positions === null
    },

    currentLocation () {
      return this.$store.getters['gps/playback/currentLocation']
    },

    csq () {
      return this.currentLocation.csq
    },

    battery () {
      return this.currentLocation.battery
    },

    currentTime () {
      return dayjs(this.currentLocation.timestamp).format('HH:mm:ss')
    },

    gps () {
      const signals = {
        1: 100,
        11: 80,
        21: 60,
        31: 40,
        41: 20,
        51: 0
      }

      if (this.currentLocation) {
        const signal = this.currentLocation.gpsSignal
        return signals[signal]
      }

      return ''
    },

    latency () {
      if (this.currentLocation) {
        const a = new Date(this.currentLocation.timestamp)
        const b = new Date(this.currentLocation.received)

        const difference = (b - a) / 1000

        return difference
      }

      return ''
    },

    coverage () {
      if (this.isBetween(this.csq, 8, 1000)) {
        return 4
      } else if (this.isBetween(this.csq, 6, 8)) {
        return 3
      } else if (this.isBetween(this.csq, 4, 6)) {
        return 2
      } else if (this.isBetween(this.csq, 0, 4)) {
        return 1
      } else {
        return 0
      }
    }
  },

  methods: {
    isBetween (x, min, max) {
      return x >= min && x <= max
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  overflow: visible;
  top: auto;
  bottom: 6rem;

  .panel-content {
    overflow: visible;
    padding: 0.75rem;
  }
}

.dropdown {
  .dropdown-menu {
    @media screen and (min-width: 1024px) {
      min-width: 20rem;
    }
  }

  .dropdown-content {
    max-height: 30rem;

    .delete {
      position: absolute;
      top: 0.25rem;
      right: 0.75rem;
    }
  }

  .dropdown-trigger .button {
    text-decoration: none;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
}
</style>
